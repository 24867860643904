import { call, put, takeEvery } from "redux-saga/effects";

import Swal from "sweetalert2";
import axios from "axios";
import { forgotPassword } from "../../actions/userList";
import history from "../../../helpers/roleAccess/privateRoute";

const apiUrl = process.env.REACT_APP_API_URL;

export function UpdatePasswordAPI(payload) {
  const url = apiUrl + "/api/v1/user/forgot/password";
  const data = {
    email: payload.password,
  };
  let userToken = localStorage.getItem("token");
  let config = { headers: { Authorization: `Bearer ${userToken}` } };

  return axios.post(url, data, config);
}

export function* updatePasswordRequest(action) {
  try {
    const response = yield call(UpdatePasswordAPI, action.payload);

    if (response) {
      yield put(
        forgotPassword.success({
          data: response.data,
        })
      );
      console.log(response.data);

      Swal.fire({
        icon: "success",
        title: "Forgot Password",
        text: "Success sending the email",
        showConfirmButton: true,
      });
      history.replace("/login");
    }
  } catch (error) {
    yield put(forgotPassword.failure(error.message));
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Forgot Password failed",
    });
  }
}

export default function* ForgotPasswordSaga() {
  yield takeEvery(forgotPassword.REQUEST, updatePasswordRequest);
}
