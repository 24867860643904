import { createRoutine } from "redux-saga-routines";
export const GET_LIST_USER = "GET_LIST_USER";
export const getListUser = createRoutine(GET_LIST_USER);

export const ADD_USER = "ADD_USER";
export const addUser = createRoutine(ADD_USER);

export const EDIT_USER = "EDIT_USER";
export const editUser = createRoutine(EDIT_USER);

export const DELETE_USER = "DELETE_USER";
export const deleteUser = createRoutine(DELETE_USER);

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const changePassword = createRoutine(CHANGE_PASSWORD);

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const forgotPassword = createRoutine(FORGOT_PASSWORD);

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const updatePassword = createRoutine(UPDATE_PASSWORD);