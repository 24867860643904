import "./filter.scss";
import "rc-slider/assets/index.css";

import Slider, { SliderTooltip } from "rc-slider";

import React from "react";
import Select from "react-select";

const { Handle } = Slider;

const FilterCardNielsen = ({
  selectedCategory,
  handleSelectCategory,
  handleSelectDepartement,
  selectedDepartement,
  dataDepartment,
  dataCategory,
  filteredItem,
  itemInput,
  handleItemInput,
  selectedItem,
  handleSelectItem,
  occupancy,
  setOccupancy,
  handleOccupancy,
}) => {
  const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value} %`}
        visible={dragging}
        placement="bottom"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  const formatOptionLabel = ({ value, label, id }) => (
    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
      <div
        style={{
          flex: 1,
          textAlign: "left",
        }}
      >
        {id}
      </div>
      <div
        style={{
          flex: 3,
        }}
      >
        {label}
      </div>
    </div>
  );
  return (
    <div className="filter-wrapper">
      <div className="filter-label">Department</div>
      <Select
        // defaultValue={[colourOptions[2], colourOptions[3]]}
        placeholder="Select Department"
        isSearchable
        name="colors"
        options={
          dataDepartment &&
          dataDepartment.map((items, i) => ({
            value: items,
            label: items.toLowerCase(),
          }))
        }
        onChange={handleSelectDepartement}
        className="basic-multi-select"
        classNamePrefix="select"
      />
      <br />
      <div className="filter-label">Category</div>
      <Select
        // defaultValue={[colourOptions[2], colourOptions[3]]}
        placeholder="Select Category"
        value={selectedCategory}
        isSearchable
        name="colors"
        options={
          dataCategory &&
          dataCategory.map((items, i) => ({
            value: items,
            label: items.toLowerCase(),
          }))
        }
        onChange={handleSelectCategory}
        className="basic-multi-select"
        classNamePrefix="select"
      />
      <br />
      <div className="filter-label">Item</div>
      <Select
        // defaultValue={[colourOptions[2], colourOptions[3]]}
        placeholder="Select Item"
        value={selectedItem}
        isSearchable
        MultiValueLabel
        name="colors"
        styles={{
          option: (base) => ({
            ...base,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // border: `1px dotted`,
            height: "100%",
          }),
        }}
        options={
          filteredItem &&
          filteredItem.map((items, i) => ({
            value: items.item_id,
            label: items.item_name,
            id: items.item_id + "\xa0\xa0",
            // label: [
            //   items.item_id + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + items.item_name,
            // ],
          }))
        }
        formatOptionLabel={formatOptionLabel}
        onChange={handleSelectItem}
        className="basic-multi-select"
        classNamePrefix="select"
      />
      <br />
      <div>
        <div className="filter-label">Occupancy Percentage</div>
        <div>
          <Slider
            style={{ marginTop: 5 }}
            className="slider-main-div"
            min={0}
            max={100}
            defaultValue={100}
            // value={occupancy}
            // range={true}
            onAfterChange={handleOccupancy}
            railStyle={{ height: 8 }}
            handleStyle={{
              height: 16,
              width: 16,
            }}
            trackStyle={{ backgroundColor: "#080c5e", height: 8 }}
            handle={handle}
          />
          <div
            style={{
              marginTop: 5,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ color: "#9a9a9a", fontSize: 12 }}>0</div>
            <div style={{ color: "#9a9a9a", fontSize: 12 }}>100</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterCardNielsen;
