import './LineChart.scss'

import React, { useEffect, useState } from 'react'

import Chart from '../../../components/Charts/InternalSales/LineChart'
import moment from 'moment'

const LineChart = ({ datas, occupancy, exportData }) => {
  const [series, setSeries] = useState([])
  const [endData, setEndData] = useState()
  const [endDataIndex, setEndDataIndex] = useState()
  const [categories, setCategories] = useState([])

  const [endDataIndex2, setEndDataIndex2] = useState()
  const [series2, setSeries2] = useState([])
  const [category, setCategory] = useState([])
  const [series3, setSeries3] = useState([])
  useEffect(() => {
    const forecast = []
    const forecastfake = []
    const actual = []
    const series = []
    const category = []
    const category2 = []
    const categorytes = []
    const categorysame =[]
    const dataExport = []
    // const filterforecast = []
    // const filteractual = []

    if (datas.length !== 0) {
      const filteractual = datas['actual']
      if (filteractual) {
        filteractual.map((x, i) => {
          actual.push(x.value === null ? 0 : x.value)
          category.push(moment(x.date).format('D MMM YY'))
          forecastfake.push(null)
        })
      }
      if (datas['forecast']) {
        datas['forecast'].map((x, i) => {
          forecast.push(x.value === null ? 0 : (x.value / 100) * occupancy)
          category2.push(moment(x.date).format('D MMM YY'))
          //  data = category.filter(y => y === moment(x.date).format('D MMM YY'))
          //  categorytes.push(data)
        })
        let datacategory = datas['forecast'].map(element => 
          moment(element.date).format('D MMM YY')
          )
        let data = datacategory.filter(el => 
          !category.find(x => x === el)
        )
        let data2 = datacategory.filter(el => 
          category.find(x => x === el)
        )
        categorysame.push(data2)
        data.forEach(elem =>
          categorytes.push(elem)
        )
      }
      series.push(
        {
          name: 'actual',
          data: actual
        },
        {
          name: 'forecast',
          data: forecastfake.slice(categorysame[0].length).concat(forecast)
        }
      )

      dataExport.push(
        {
          name: 'actual',
          values: actual,
          labels: category.concat(categorytes)
        },
        {
          name: 'forecast',
          values: forecastfake.slice(categorysame[0].length).concat(forecast),
          labels: category.concat(categorytes)
        }
      )

      if (exportData) {
        exportData(dataExport)
      }
    } else {
      setSeries2([])
      setCategory([])
      setEndData()
      setEndDataIndex()
    }
  }, [datas, exportData, occupancy])
// console.log(datas,"datas");
  useEffect(() => {
    const objForecast = []
    const objActual =[]
    if (datas.length !== 0) {
     datas["actual"].map((x,i)=>
     objActual.push({
          y:x.value,
          x:new Date(x.date).getTime()
          // new Date(x.date).getTime()
        })
      )
      datas["forecast"].map((x,i)=>
      objForecast.push({
        y: x.value === null ? 0 : ((x.value / 100)* occupancy).toFixed(2),
        x: new Date(x.date).getTime()
      })
    )
    }
    setSeries3([{
      data:objActual,
      name:"actual"
    },
    {
      data:objForecast,
      name:"forecast"
    }])
  }, [datas, exportData, occupancy])

  // useEffect(() => {
  //   if (data && data.data) {
  //     setCategories(data.categories);
  //     setEndData(data.endData);
  //     setEndDataIndex(data.endDataIndex);
  //     setSeries(data.data);

  //     let tmpData = data.data[1].data;
  //     let DataAfter = [];
  //     tmpData.forEach((itm) => {
  //       if (itm == null) {
  //         DataAfter.push("");
  //       } else {
  //         DataAfter.push(itm);
  //       }
  //     });

  //     let dataToExport = [];
  //     dataToExport.push(
  //       {
  //         name: data.data[0].name,
  //         values: data.data[0].data,
  //         labels: data.categories,
  //       },
  //       {
  //         name: data.data[1].name,
  //         values: DataAfter,
  //         labels: data.categories,
  //       }
  //     );
  //     if (exportData) {
  //       exportData(dataToExport);
  //     }
  //   }
  // }, [data, exportData]);

  return (
    <div className='linechart-internalsales-wrapper'>
      <div className='card-title'>Sales Quantity Forecast</div>
      <div className='linechart-internalsales-content'>
        <Chart
          categories={category.sort((a, b) => moment(a, 'D MMM YY').diff(moment(b, 'D MMM YY')))}
          seriesData={series3}
          endData={endData}
          endDataIndex={endDataIndex}
        />
      </div>
    </div>
  )
}

export default LineChart
