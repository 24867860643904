import { call, put, takeEvery } from 'redux-saga/effects'

import Swal from 'sweetalert2'
import axios from 'axios'
import { getForecastData } from '../../actions/forecastData'

const apiUrl = process.env.REACT_APP_API_URL

export function addUserAPI (payload) {
  const departemet = encodeURIComponent(payload[0])
  const category = encodeURIComponent(payload[1])
  const item = encodeURIComponent(payload[2])

  let url = `${apiUrl}/api/v1/forecast/data?item=${item}&department=${departemet}&category=${category}`
  let token = localStorage.getItem('token')

  let config = { headers: { Authorization: `Bearer ${token}` } }

  return axios.get(url, config)
}

export function * addUserRequest (action) {
  try {
    const response = yield call(addUserAPI, action.payload)

    if (response) {
      yield put(
        getForecastData.success({
          data: response.data.data
        })
      )
      //   Swal.fire({
      //     icon: "success",
      //     title: "Add User Success",
      //   });
    }
  } catch (error) {
    console.log('salah', error)
    yield put(getForecastData.failure(error.message))
  }
}

export default function * ForecastSaga () {
  yield takeEvery(getForecastData.REQUEST, addUserRequest)
}
