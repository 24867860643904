import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Chart from 'react-apexcharts'

//import { formatMillion } from "../../../helpers/formatDollar";

const LineChart = dataChart => {
 

  const [width, setWidth] = useState(window.innerWidth)
  const [fontSize, setFontSize] = useState('10px')

  const [options, setOptions] = useState({})
  const [series, setSeries] = useState([])

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    width === 1366 ? setFontSize('10px') : setFontSize('12px')
    if (dataChart.categories) {
      setOptions({
        // annotations: {
        //   position: "back",
        //   xaxis: [
        //     {
        //       x: "1 Aug 21",
        //       borderColor: "#2519e2",
        //       label: {
        //         borderColor: "#2519e2",
        //         style: {
        //           color: "#fff",
        //           background: "#2519e2",
        //         },
        //         orientation: "horizontal",
        //       },
        //     },
        //   ],
        // },
        chart: {
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
            tools: {
              download: false
            }
          }
        },
        colors: ['#f44336', '#2519e2'],
        grid: {
          padding: {
            left: 10,
            top: -22,
            right: 10
            // bottom: width === 1366 ? -10 : -18,
          },
          strokeDashArray: 3
        },
        tooltip: {
          style: {
            fontSize,
            fontFamily:
              'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif'
          }
        },
        xaxis: {
          type: 'datetime',

          categories: dataChart.categories,
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: false
          },
          labels: {
            style: {
              fontSize,
              fontFamily:
                "Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
            },
            formatter: function (value) {
              return moment(value).format('D MMM YY');
            }
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize,
              fontFamily:
                'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif'
            },
            formatter: value => {
              //   if (value >= 1000000) {
              //     return formatMillion(value) + "M";
              //   }
              return value
            }
          }
        },
        stroke: {
          width: width === 1366 ? 1 : 2
        },
        markers: {
          size: 3,
          hover: {
            size: 5
          },
          discrete: [
            {
              seriesIndex: 1,
              // dataPointIndex: dataChart.endDataIndex,
              fillColor: '#f44336',
              strokeColor: '#fff',
              size: 3
            }
          ]
        },
        legend: {
          fontSize,
          fontFamily:
            'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
          show: true,
          position: 'top',
          horizontalAlign: 'right'
        }
      })

      setSeries(dataChart.seriesData)
    }
    // console.log("series =>",dataChart);
  }, [dataChart, width, fontSize])
  // console.log(series, 'series')
  // console.log(dataChart.categories)
  return <Chart options={options} series={series} width='100%' height='100%' />
}

export default LineChart
