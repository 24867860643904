import { Redirect, Route, withRouter } from "react-router-dom";

import React from "react";
import { createBrowserHistory } from "history";
// import ability from "./ability";
const history = createBrowserHistory();
export default withRouter(history);

// export const PrivateRoute = ({ component: Component, ...path }) => {
//   return (
//     <Route
//       {...path}
//       render={(props) =>
//         ability.can("view", path.path) ? (
//           <Component {...props} />
//         ) : (
//           <Redirect to="/notfound" />
//         )
//       }
//     />
//   );
// };

// export const LoginRoute = ({ component: Component, ...path }) => {
//   return (
//     <Route
//       {...path}
//       render={(props) =>
//         localStorage.getItem("token") ? (
//           <Redirect to="/internalsales" />
//         ) : (
//           <Component {...props} />
//         )
//       }
//     />
//   );
// };
