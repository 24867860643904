import React from "react";
import { Link } from "react-router-dom";
// import Can from "../../helpers/roleAccess/Can";
import "./sideMenu.scss";

const SideMenu = ({ listMenu, title }) => {
  return (
    <div className="sidemenu-wrapper">
      <div className="back-dashboard list-menu">{title}</div>
      {listMenu &&
        listMenu.map((menu) => (
          // <Can I="view" a={menu.url}>
            <div
              className="list-menu"
              style={
                window.location.pathname === menu.url
                  ? { backgroundColor: "white", fontWeight: "bold" }
                  : {}
              }
            >
              <Link to={menu.url}>{menu.name}</Link>
            </div>
          // </Can>
        ))}
    </div>
  );
};

export default SideMenu;
