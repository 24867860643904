import {
  getFilterCategory,
  getFilterDepartment,
  getFilterItem,
} from "../actions/filterMenu";

import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  response_filter_department: [],
  response_filter_category: [],
  response_filter_item: [],
  pending: true,
  error: false,
};

const filterMenu = handleActions(
  {
    [getFilterDepartment.REQUEST]: (state, { payload }) => ({
      ...state,
    }),
    [getFilterDepartment.SUCCESS]: (state, { payload }) => ({
      ...state,
      response_filter_department: payload.data,
      pending: false,
      error: false,
    }),
    [getFilterDepartment.FAILURE]: (state) => ({
      ...state,
      error: true,
      pending: false,
    }),
    [getFilterCategory.REQUEST]: (state, { payload }) => ({
      ...state,
      departement: payload.departement,
    }),
    [getFilterCategory.SUCCESS]: (state, { payload }) => ({
      ...state,
      response_filter_category: payload.data,
      pending: false,
      error: false,
    }),
    [getFilterCategory.FAILURE]: (state) => ({
      ...state,
      error: true,
      pending: false,
    }),
    [getFilterItem.REQUEST]: (state, { payload }) => ({
      ...state,
      departement: payload.departement,
      category: payload.category,
    }),
    [getFilterItem.SUCCESS]: (state, { payload }) => ({
      ...state,
      response_filter_item: payload.data,
      pending: false,
      error: false,
    }),
    [getFilterItem.FAILURE]: (state) => ({
      ...state,
      error: true,
      pending: false,
    }),
  },
  INITIAL_STATE
);

export default filterMenu;
