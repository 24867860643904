import "../login.scss";

import React, { useState, useEffect} from "react";
import { func } from "prop-types";
import { connect } from "react-redux";

import history from "../../../helpers/roleAccess/privateRoute";
import { forgotPassword } from "../../../redux-saga/actions/userList";
import { withRouter } from "react-router-dom";

const ForgotPasswordClass = (props) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleInputChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSendEmail = async () => {
    let forgot_query = {
      password: email,
    };
    await props.forgotpassword(forgot_query);
    // setEmail("");
    // history.replace("update-password");
  };
  // useEffect(() => {
  //  if (props.response_forgot_password !== 0) {
    
  //  }
  // }, [props.response_forgot_password]);

  return (
    <div className="login-wrapper">
      <div className="login-content-wrapper">
        <div className="brand-logo">
          <img className="img" src="./main-logo1.png" alt="" />
          <img className="img2" src="./main-logo2.png" alt="" />
          <text className="text-update-password">Forgot Password</text>
        </div>
        <hr />
        <div className="input-form">
          <input
            type="email"
            placeholder="Email"
            value={email}
            name="email"
            onChange={handleInputChangeEmail}
          />

          <button onClick={handleSendEmail} disabled={loading}>
            {loading ? "Send..." : "Send"}
          </button>
        </div>
      </div>
    </div>
  );
};
ForgotPasswordClass.propTypes = {
  forgotpassword: func.isRequired,
};
const mapStateToProps = ({ userList: { response_forgot_password, pending, error } }) => ({
  response_forgot_password,
  pending,
  error,
});
const mapDispatchToProps = (dispatch) => ({
  forgotpassword: (forgot_query) => dispatch(forgotPassword.request(forgot_query)),
});
const ForgotPassword = connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordClass);

export default ForgotPassword;
