import { call, put, takeEvery } from "redux-saga/effects";

import axios from "axios";
import { getListUser } from "../../actions/userList";
const apiUrl = process.env.REACT_APP_API_URL;

export function getUserList() {
  const url =apiUrl+"/api/v1/user/listUser";
  let userToken = localStorage.getItem("token");
  let config = { headers: { Authorization: `Bearer ${userToken}` } };
  return axios.get(url, config);
}

export function* getUserListRequest(action) {
  try {
    const response = yield call(getUserList);
    if (response) {
      yield put(
        getListUser.success({
          data: response.data.data,
        })
      );
    }
  } catch (error) {
    yield put(getListUser.failure(error.message));
  }
}

export default function* userListSaga() {
  yield takeEvery(getListUser.REQUEST, getUserListRequest);
}
