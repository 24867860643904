import { handleActions } from "redux-actions";
import { postLogin } from "../actions/login";

const INITIAL_STATE = {
  response_login: [],
  pending: true,
  error: false
};

const dologin = handleActions(
  {
    [postLogin.REQUEST]: (state, { payload }) => ({
      ...state,
      email: payload.email,
      password: payload.password
    }),
    [postLogin.SUCCESS]: (state, { payload }) => ({
      ...state,
      response_login: payload.data,
      pending: false,
      error: false
    }),
    [postLogin.FAILURE]: state => ({
      ...state,
      error: true,
      pending: false
    })
  },
  INITIAL_STATE
);

export default dologin;
