import { call, put, takeEvery } from "redux-saga/effects";

import axios from "axios";
import { postAuditTrails } from "../../actions/auditTrails";

const apiUrl = process.env.REACT_APP_API_URL;

export function postAuditAPI(payload) {
  let url = apiUrl + "/api/v1/audit-trail/create";
  const data = {
    username: payload.username,
    menu: payload.menu,
  };
  let token = localStorage.getItem("token");
  let config = { headers: { Authorization: `Bearer ${token}` } };

  return axios.post(url, data, config);
}

export function* postAuditRequest(action) {
  try {
    const response = yield call(postAuditAPI, action.payload);

    console.log(response);

    if (response) {
      yield put(
        postAuditTrails.success({
          data: response.data,
        })
      );
    }
  } catch (error) {
    yield put(postAuditTrails.failure(error.message));
  }
}

export default function* postAuditSaga() {
  yield takeEvery(postAuditTrails.REQUEST, postAuditRequest);
}
