import { all, fork } from "redux-saga/effects";

import CategoryListFilterSaga from "./filter/filterCategoryMenu";
import DepartmentListFilterSaga from "./filter/filterDepartmentMenu";
import ForgotPasswordSaga from "./user/forgotPassword";
import ItemListFilterSaga from "./filter/filterItemMenu";
import UpdatePasswordSaga from "./user/updatePassword";
import addUserSaga from "./user/addUser";
import changePasswordSaga from "./user/changePassword";
import deleteUserSaga from "./user/deleteUser";
import editUserSaga from "./user/editUser";
import getAuditSaga from "./user/getAuditTrails";
import loginSaga from "./user/login";
import postAuditSaga from "./user/postAuditTrails";
import userListSaga from "./user/userList";
import ForecastSaga from "./forecast/getForecast";

export default function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(getAuditSaga),
    fork(postAuditSaga),
    fork(userListSaga),
    fork(addUserSaga),
    fork(editUserSaga),
    fork(deleteUserSaga),
    fork(changePasswordSaga),
    fork(ForgotPasswordSaga),
    fork(UpdatePasswordSaga),
    fork(DepartmentListFilterSaga),
    fork(CategoryListFilterSaga),
    fork(ItemListFilterSaga),
    fork(ForecastSaga),
  ]);
}
