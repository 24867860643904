import "./menu.scss";

import React, { useEffect, useState } from "react";
import {
  addUser,
  deleteUser,
  editUser,
  getListUser,
} from "../../redux-saga/actions/userList";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getAuditTrails,
  postAuditTrails,
} from "../../redux-saga/actions/auditTrails";

import FullLayout from "../../components/Container/Layout/FullLayout";
import { InputTextBox } from "../../components/Form";
import Loading from "../../components/Loading/loading";
import Swal from "sweetalert2";
import UserTable from "../../components/Tables/Settings/UserTable";
import excelTrails from "../../helpers/ExportExcelAuditTrails";
import { func } from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";

function UserMenuClass(props) {
  const [show, setShow] = useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleOpenClose = (event) => {
    setShow(!show);
  };
  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };
  const handleAddData = async () => {
    const data = {
      email: email,
      username: name,
      password: password,
    };
    await props.addUser(data);

    setName("");
    setEmail("");
    setPassword("");
    setTimeout(() => {
      props.getUserList();
    }, 1000);
    setShow(!show);
  };

  const handleEdit = async (username, email, id) => {
    const data = {
      email: email,
      username: username,
      id: id,
    };
    await props.editUser(data);
    setShowModal(false);
    setTimeout(() => {
      props.getUserList();
    }, 1000);
  };

  const handleDeleteUser = async (id) => {
    const data = {
      id: id,
    };
    await props.deleteUser(data);
    setShowModal(false);
    setTimeout(() => {
      props.getUserList();
    }, 1000);
  };

  const exportAuditTrails = async () => {
    if (props.auditTrails) {
      excelTrails(props.auditTrails);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Data Not Found",
        text: "There is no data to export",
        showConfirmButton: true,
      });
    }
  };

  useEffect(() => {
    const data = {
      username: localStorage.getItem("username"),
      menu: "User Menu",
    };
    props.postAudit(data);
    props.getUserList();
    const params = {
      username: localStorage.getItem("username"),
      start_date: moment().subtract(3, "months").format("YYYY-M-DD"),
      end_date: moment().add(1, "days").format("YYYY-M-DD"),
    };
    props.getAudit(params);
  }, []);

  return (
    <FullLayout>
      <div className="role-setting-wrapper">
        <div className="card-title">User List</div>
        <div className="btn-group">
          <div className="buton-show" onClick={handleOpenClose}>
            Add New
          </div>
          <a className="buton-exp" onClick={exportAuditTrails}>
            Export Audit Trails
          </a>
        </div>
        {show === true && (
          <div className="form-container menu">
            <InputTextBox
              label={"Name"}
              value={name}
              height={30}
              width="98%"
              color={"#002370"}
              placeholder={"Name"}
              onchange={handleChangeName}
            />

            <InputTextBox
              label={"Email"}
              value={email}
              height={30}
              width="98%"
              color={"#002370"}
              placeholder={"Email"}
              onchange={handleChangeEmail}
            />

            <InputTextBox
              label={"Password"}
              value={password}
              height={30}
              type="password"
              width="98%"
              color={"#002370"}
              placeholder={"Password"}
              onchange={handleChangePassword}
            />

            <div
              style={{ width: "100%" }}
              className="buton-show big"
              onClick={handleAddData}
            >
              Submit
            </div>
          </div>
        )}
        <div>
          {props.pending ? (
            <Loading />
          ) : (
            <UserTable
              listUser={props.response_list_user}
              edit={handleEdit}
              handleDeleteUser={handleDeleteUser}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}
        </div>
      </div>
    </FullLayout>
  );
}
UserMenuClass.propTypes = {
  getAudit: func.isRequired,
  postAudit: func.isRequired,
  getUserList: func.isRequired,
  addUser: func.isRequired,
  editUser: func.isRequired,
  deleteUser: func.isRequired,
};

const mapStateToProps = ({
  audit: {
    response_auditTrails,
    auditTrails,
    response_list_user,
    response_add_user,
    response_edit_user,
    response_delete_user,
    pending,
    error,
  },
}) => ({
  response_auditTrails,
  auditTrails,
  response_list_user,
  response_add_user,
  response_edit_user,
  response_delete_user,
  pending,
  error,
});

const mapDispatchToProps = (dispatch) => ({
  getAudit: (params) => dispatch(getAuditTrails.request(params)),
  postAudit: (data) => dispatch(postAuditTrails.request(data)),
  getUserList: () => dispatch(getListUser.request()),
  addUser: (data) => dispatch(addUser.request(data)),
  editUser: (data) => dispatch(editUser.request(data)),
  deleteUser: (data) => dispatch(deleteUser.request(data)),
});

const UserMenu = connect(mapStateToProps, mapDispatchToProps)(UserMenuClass);

export default UserMenu;
