import pptxgenjs from "pptxgenjs";

export const SalesForecastPPT = (dataInternal) => {
  const filePPT = new pptxgenjs();
  const Judul = filePPT.addSlide();
  Judul.addText("SUDESTADA FORECAST - SALES QUANTITY", {
    x: 0,
    y: 2,
    w: "100%",
    h: 1,
    align: "center",
    color: "0088CC",
    fill: "F1F1F1",
    fontSize: 24,
  });

  const firstSlide = filePPT.addSlide();
  firstSlide.addText("Sales Quantity Forecast", {
    x: 0.2,
    y: 0.6,
    align: "left",
    fontSize: 14,
  });

  firstSlide.addChart(filePPT.ChartType.line, dataInternal, {
    x: 0.3,
    y: 0.9,
    w: "94%",
    h: "80%",
    align: "center",
    showLegend: true,
    legendPos: "t",
    catAxisLabelFontSize: 10,
    valAxisLabelFontSize: 10,
  });

  filePPT.writeFile("Sales Quantity Forecast");
};
