import React, { useEffect, useState } from "react";

import InternalSales from "../InternalSales";
import LayoutWithMenu from "../../components/Container/Layout/layoutWithMenu";
import { connect } from "react-redux";
import { func } from "prop-types";
import history from "../../helpers/roleAccess/privateRoute";
import { postAuditTrails } from "../../redux-saga/actions/auditTrails";
import { withRouter } from "react-router-dom";

const DashboardClass = (props) => {
  useEffect(() => {
    if (localStorage.getItem("username") == undefined) {
      history.push("login");
    }
    const data = {
      username: localStorage.getItem("username"),
      menu: "Dashboard",
    };
    props.postAudit(data);
  }, []);

  return (
    // <LayoutWithMenu>
    //   <div></div>
    //   <InternalSales />
    // </LayoutWithMenu>
    <InternalSales />
  );
};

DashboardClass.propTypes = {
  postAudit: func.isRequired,
};

const mapStateToProps = ({
  audit: { response_auditTrails, pending, error },
}) => ({
  response_auditTrails,
  pending,
  error,
});

const mapDispatchToProps = (dispatch) => ({
  postAudit: (data) => dispatch(postAuditTrails.request(data)),
});
const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardClass);

export default withRouter(Dashboard);
