// import ability from "../../helpers/roleAccess/ability";
// import AuthService from "../../services/Auth/AuthService";

import "./header.scss";

import React, { useEffect, useState } from "react";

import CloseMenu from "../../../../helpers/CloseMenu";
import history from "../../../../helpers/roleAccess/privateRoute";

// import { useMsal } from "@azure/msal-react";





// import decode from "jwt-decode";
// const Auth = new AuthService();

const Header = () => {
  // const { instance } = useMsal();

  const [showProfile, setShowProfile] = useState(false);

  // useEffect(() => {
  //   if (token) {
  //     window.ymConfig = {
  //       bot: "x1613651859570",
  //       payload: { bearer_token: token },
  //     };
  //     let w = window,
  //       ic = w.YellowMessenger;
  //     if ("function" === typeof ic) {
  //       ic("reattach_activator");
  //       ic("update", w.ymConfig);
  //     } else {
  //       let d = document,
  //         i = function () {
  //           i.c(arguments);
  //         };
  //       function l() {
  //         let e = d.createElement("script");
  //         e.type = "text/javascript";
  //         e.async = !0;
  //         e.src = "https://app.yellowmessenger.com/widget/main.js";
  //         let t = d.getElementsByTagName("script")[0];
  //         t.parentNode.insertBefore(e, t);
  //       }
  //       i.q = [];
  //       i.c = function (e) {
  //         i.q.push(e);
  //       };
  //       w.YellowMessenger = i;
  //       l();
  //     }
  //   }
  // }, [token]);

  const handleShowProfile = () => {
    setShowProfile(!showProfile);
  };

  const handleSignOut = () => {
    // instance.logout();
    // Auth.logout();
    // localStorage.clear();
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    history.replace("login");
  };

  const handleChangePassword = () => {
    history.replace("change-password");
  };

  // const pathUrl = window.location.pathname.split("/")[1];

  return (
    <div className="header-wrapper">
      <div className="left-item">
        {/* <i className="fa fa-bars fa-lg" aria-hidden="true"></i> */}
        <div className="brand-logo">
          <img src="/danone-logo.png" alt="" />
        </div>
        <div className="title">Sudestada Forecast</div>
      </div>
      <div className="right-item">
        <div
          className="icon-wrapper"
          onClick={() => history.push("/user-menu")}
        >
          <i className="fa fa-gear fa-lg" aria-hidden="true"></i>
        </div>
        <div className="icon-wrapper">
          <i
            className="fa fa-user-circle fa-lg"
            aria-hidden="true"
            onClick={handleShowProfile}
          ></i>
          {showProfile && (
            <CloseMenu handleShow={handleShowProfile}>
              <div className="dropdown-content">
                <div className="username">
                  Hei, {localStorage.getItem("username")}
                </div>
                <hr />
                <div className="button-list" onClick={handleChangePassword}>
                  Change Password
                </div>
                <hr />
                <div className="button-list" onClick={handleSignOut}>
                  Sign Out
                </div>
              </div>
            </CloseMenu>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
