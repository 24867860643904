import { call, put, takeEvery } from "redux-saga/effects";

import Swal from "sweetalert2";
import axios from "axios";
import history from "../../../helpers/roleAccess/privateRoute";
import { updatePassword } from "../../actions/userList";

const apiUrl = process.env.REACT_APP_API_URL;

export function UpdatePasswordAPI(payload) {
  const url = apiUrl + "/api/v1/user/change/forgot/password";
  const data = {
    token : payload.token,
    password: payload.password,
    repeat_password: payload.repeat_password,
  };
  // let userToken = localStorage.getItem("token");
  // let config = { headers: { Authorization: `Bearer ${userToken}` } };

  return axios.post(url, data);
}

export function* updatePasswordRequest(action) {
  try {
    const response = yield call(UpdatePasswordAPI, action.payload);

    if (response) {
      yield put(
        updatePassword.success({
          data: response.data,
        })
      );
      console.log(response.data);

      Swal.fire({
        icon: "success",
        title: "Update Password",
        text: "Succes to update password",
        showConfirmButton: true,
      });
      history.replace("/login");
    }
  } catch (error) {
    yield put(updatePassword.failure(error.message));
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Update Password failed",
    });
  }
}

export default function* UpdatePasswordSaga() {
  yield takeEvery(updatePassword.REQUEST, updatePasswordRequest);
}
