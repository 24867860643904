import { call, put, takeEvery } from "redux-saga/effects";

import axios from "axios";
import { getFilterDepartment } from "../../actions/filterMenu";

const apiUrl = process.env.REACT_APP_API_URL;

export function getDepartmentListAPI() {
  const url = apiUrl + "/api/v1/forecast/filter/departement";
  let userToken = localStorage.getItem("token");
  let config = { headers: { Authorization: `Bearer ${userToken}` } };
  return axios.get(url, config);
}

export function* getDepartmentListRequest(action) {
  try {
    const response = yield call(getDepartmentListAPI);
    if (response) {
      yield put(
        getFilterDepartment.success({
          data: response.data.data,
        })
      );
    }
  } catch (error) {
    yield put(getFilterDepartment.failure(error.message));
  }
}

export default function* DepartmentListFilterSaga() {
  yield takeEvery(getFilterDepartment.REQUEST, getDepartmentListRequest);
}
