import {
  addUser,
  deleteUser,
  editUser,
  getListUser,
} from "../actions/userList";
import { getAuditTrails, postAuditTrails } from "../actions/auditTrails";

import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  response_auditTrails: [],
  auditTrails: [],
  response_list_user: [],
  response_add_user: [],
  response_edit_user: [],
  response_delete_user: [],
  pending: true,
  error: false,
};

const audit = handleActions(
  {
    [getAuditTrails.REQUEST]: (state, { payload }) => ({
      ...state,
      username: payload.username,
      start_date: payload.start_date,
      end_date: payload.end_date,
    }),
    [getAuditTrails.SUCCESS]: (state, { payload }) => ({
      ...state,
      auditTrails: payload.data,
      pending: false,
      error: false,
    }),
    [getAuditTrails.FAILURE]: (state) => ({
      ...state,
      error: true,
      pending: false,
    }),
    [postAuditTrails.REQUEST]: (state, { payload }) => ({
      ...state,
      username: payload.username,
      menu: payload.menu,
    }),
    [postAuditTrails.SUCCESS]: (state, { payload }) => ({
      ...state,
      response_auditTrails: payload.data,
      pending: false,
      error: false,
    }),
    [postAuditTrails.FAILURE]: (state) => ({
      ...state,
      error: true,
      pending: false,
    }),
    [getListUser.REQUEST]: (state, { payload }) => ({
      ...state,
    }),
    [getListUser.SUCCESS]: (state, { payload }) => ({
      ...state,
      response_list_user: payload.data,
      pending: false,
      error: false,
    }),
    [getListUser.FAILURE]: (state) => ({
      ...state,
      error: true,
      pending: false,
    }),

    [addUser.REQUEST]: (state, { payload }) => ({
      ...state,
      email: payload.email,
      username: payload.username,
      password: payload.password,
    }),
    [addUser.SUCCESS]: (state, { payload }) => ({
      ...state,
      response_add_user: payload.data,
      pending: false,
      error: false,
    }),
    [addUser.FAILURE]: (state) => ({
      ...state,
      error: true,
      pending: false,
    }),

    [editUser.REQUEST]: (state, { payload }) => ({
      ...state,
      email: payload.email,
      username: payload.username,
    }),
    [editUser.SUCCESS]: (state, { payload }) => ({
      ...state,
      response_edit_user: payload.data,
      pending: false,
      error: false,
    }),
    [editUser.FAILURE]: (state) => ({
      ...state,
      error: true,
      pending: false,
    }),

    [deleteUser.REQUEST]: (state, { payload }) => ({
      ...state,
      id: payload.id,
    }),
    [deleteUser.SUCCESS]: (state, { payload }) => ({
      ...state,
      response_delete_user: payload.data,
      pending: false,
      error: false,
    }),
    [deleteUser.FAILURE]: (state) => ({
      ...state,
      error: true,
      pending: false,
    }),
  },
  INITIAL_STATE
);

export default audit;
