import { call, put, takeEvery } from "redux-saga/effects";

import Swal from "sweetalert2";
import axios from "axios";
import { editUser } from "../../actions/userList";
const apiUrl = process.env.REACT_APP_API_URL;

export function editUserAPI(payload) {
  let url = apiUrl + "/api/v1/user/" + payload.id;
  const data = {
    email: payload.email,
    username: payload.username,
  };
  let token = localStorage.getItem("token");

  let config = { headers: { Authorization: `Bearer ${token}` } };

  return axios.put(url, data, config);
}

export function* editUserRequest(action) {
  try {
    const response = yield call(editUserAPI, action.payload);

    if (response) {
      yield put(
        editUser.success({
          data: response.data,
        })
      );
      Swal.fire({
        icon: "success",
        title: "edit User Success",
      });
    }
  } catch (error) {
    yield put(editUser.failure(error.message));
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}

export default function* editUserSaga() {
  yield takeEvery(editUser.REQUEST, editUserRequest);
}
