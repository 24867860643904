import { getForecastData } from "../actions/forecastData";
import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  response_forecast_data: [],
  pending: true,
  error: false,
};

const forecast = handleActions(
  {
    [getForecastData.REQUEST]: (state, { payload }) => ({
      ...state,
      item: payload.item,
    }),
    [getForecastData.SUCCESS]: (state, { payload }) => ({
      ...state,
      response_forecast_data: payload.data,
      pending: false,
      error: false,
    }),
    [getForecastData.FAILURE]: (state) => ({
      ...state,
      error: true,
      pending: false,
    }),
  },
  INITIAL_STATE
);

export default forecast;
