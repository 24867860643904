import * as fs from "file-saver";

import { Workbook } from "exceljs";

const ExportExcelSales = (data, occupancy) => {
  let workbook = new Workbook();
  let internalSales = workbook.addWorksheet("Internal Sales");
  let headerSales = ["Periode", "Actual", "Forecasted"];
  internalSales.addRow(headerSales);
  let rows = [];
  data["actual"].map((item) => {
    rows.push({
      periode: item.date,
      actual: item.value === null ? 0 : item.value,
      forecast: null,
    });
  });

  data["forecast"].map((item) => {
    rows.push({
      periode: item.date,
      actual: null,
      forecast: item.value === null ? 0 : (item.value / 100) * occupancy,
    });
  });

  for (let x1 of rows) {
    let x2 = Object.keys(x1);
    let temp = [];
    for (let y of x2) {
      temp.push(x1[y]);
    }

    console.log(temp);
    internalSales.addRow(temp);
  }

  let fname = "Forecast Internal Sales";
  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, fname + ".xlsx");
  });
};

export default ExportExcelSales;
