window.seed = (function () {
    const data = [
        {
            "month_year": "January 2017",
            "status_type": "actual",
            "data": {
                "date": "2017-01-01T00:00:00.000Z",
                "internal_volume": 727486041
            }
        },
        {
            "month_year": "February 2017",
            "status_type": "actual",
            "data": {
                "date": "2017-02-01T00:00:00.000Z",
                "internal_volume": 641503232
            }
        },
        {
            "month_year": "March 2017",
            "status_type": "actual",
            "data": {
                "date": "2017-03-01T00:00:00.000Z",
                "internal_volume": 754823279
            }
        },
        {
            "month_year": "April 2017",
            "status_type": "actual",
            "data": {
                "date": "2017-04-01T00:00:00.000Z",
                "internal_volume": 703470516
            }
        },
        {
            "month_year": "May 2017",
            "status_type": "actual",
            "data": {
                "date": "2017-05-01T00:00:00.000Z",
                "internal_volume": 772729620
            }
        },
        {
            "month_year": "June 2017",
            "status_type": "actual",
            "data": {
                "date": "2017-06-01T00:00:00.000Z",
                "internal_volume": 587809422
            }
        },
        {
            "month_year": "July 2017",
            "status_type": "actual",
            "data": {
                "date": "2017-07-01T00:00:00.000Z",
                "internal_volume": 806138251
            }
        },
        {
            "month_year": "August 2017",
            "status_type": "actual",
            "data": {
                "date": "2017-08-01T00:00:00.000Z",
                "internal_volume": 831778143
            }
        },
        {
            "month_year": "September 2017",
            "status_type": "actual",
            "data": {
                "date": "2017-09-01T00:00:00.000Z",
                "internal_volume": 760062985
            }
        },
        {
            "month_year": "October 2017",
            "status_type": "actual",
            "data": {
                "date": "2017-10-01T00:00:00.000Z",
                "internal_volume": 777131141
            }
        },
        {
            "month_year": "November 2017",
            "status_type": "actual",
            "data": {
                "date": "2017-11-01T00:00:00.000Z",
                "internal_volume": 775834961
            }
        },
        {
            "month_year": "December 2017",
            "status_type": "actual",
            "data": {
                "date": "2017-12-01T00:00:00.000Z",
                "internal_volume": 675041006
            }
        },
        {
            "month_year": "January 2018",
            "status_type": "actual",
            "data": {
                "date": "2018-01-01T00:00:00.000Z",
                "internal_volume": 745839509
            }
        },
        {
            "month_year": "February 2018",
            "status_type": "actual",
            "data": {
                "date": "2018-02-01T00:00:00.000Z",
                "internal_volume": 641425579
            }
        },
        {
            "month_year": "March 2018",
            "status_type": "actual",
            "data": {
                "date": "2018-03-01T00:00:00.000Z",
                "internal_volume": 765236191
            }
        },
        {
            "month_year": "April 2018",
            "status_type": "actual",
            "data": {
                "date": "2018-04-01T00:00:00.000Z",
                "internal_volume": 735674775
            }
        },
        {
            "month_year": "May 2018",
            "status_type": "actual",
            "data": {
                "date": "2018-05-01T00:00:00.000Z",
                "internal_volume": 789063939
            }
        },
        {
            "month_year": "June 2018",
            "status_type": "actual",
            "data": {
                "date": "2018-06-01T00:00:00.000Z",
                "internal_volume": 601948576
            }
        },
        {
            "month_year": "July 2018",
            "status_type": "actual",
            "data": {
                "date": "2018-07-01T00:00:00.000Z",
                "internal_volume": 842418238
            }
        },
        {
            "month_year": "August 2018",
            "status_type": "actual",
            "data": {
                "date": "2018-08-01T00:00:00.000Z",
                "internal_volume": 762371314
            }
        },
        {
            "month_year": "September 2018",
            "status_type": "actual",
            "data": {
                "date": "2018-09-01T00:00:00.000Z",
                "internal_volume": 771638982
            }
        },
        {
            "month_year": "October 2018",
            "status_type": "actual",
            "data": {
                "date": "2018-10-01T00:00:00.000Z",
                "internal_volume": 838554968
            }
        },
        {
            "month_year": "November 2018",
            "status_type": "actual",
            "data": {
                "date": "2018-11-01T00:00:00.000Z",
                "internal_volume": 778482915
            }
        },
        {
            "month_year": "December 2018",
            "status_type": "actual",
            "data": {
                "date": "2018-12-01T00:00:00.000Z",
                "internal_volume": 725055979
            }
        },
        {
            "month_year": "January 2019",
            "status_type": "actual",
            "data": {
                "date": "2019-01-01T00:00:00.000Z",
                "internal_volume": 779830756
            }
        },
        {
            "month_year": "February 2019",
            "status_type": "actual",
            "data": {
                "date": "2019-02-01T00:00:00.000Z",
                "internal_volume": 677918765
            }
        },
        {
            "month_year": "March 2019",
            "status_type": "actual",
            "data": {
                "date": "2019-03-01T00:00:00.000Z",
                "internal_volume": 739285402
            }
        },
        {
            "month_year": "April 2019",
            "status_type": "actual",
            "data": {
                "date": "2019-04-01T00:00:00.000Z",
                "internal_volume": 730314286
            }
        },
        {
            "month_year": "May 2019",
            "status_type": "actual",
            "data": {
                "date": "2019-05-01T00:00:00.000Z",
                "internal_volume": 825153736
            }
        },
        {
            "month_year": "June 2019",
            "status_type": "actual",
            "data": {
                "date": "2019-06-01T00:00:00.000Z",
                "internal_volume": 622203412
            }
        },
        {
            "month_year": "July 2019",
            "status_type": "actual",
            "data": {
                "date": "2019-07-01T00:00:00.000Z",
                "internal_volume": 844496401
            }
        },
        {
            "month_year": "August 2019",
            "status_type": "actual",
            "data": {
                "date": "2019-08-01T00:00:00.000Z",
                "internal_volume": 781823096
            }
        },
        {
            "month_year": "September 2019",
            "status_type": "actual",
            "data": {
                "date": "2019-09-01T00:00:00.000Z",
                "internal_volume": 775198613
            }
        },
        {
            "month_year": "October 2019",
            "status_type": "actual",
            "data": {
                "date": "2019-10-01T00:00:00.000Z",
                "internal_volume": 780162097
            }
        },
        {
            "month_year": "November 2019",
            "status_type": "actual",
            "data": {
                "date": "2019-11-01T00:00:00.000Z",
                "internal_volume": 828702347
            }
        },
        {
            "month_year": "December 2019",
            "status_type": "actual",
            "data": {
                "date": "2019-12-01T00:00:00.000Z",
                "internal_volume": 804820069
            }
        },
        {
            "month_year": "January 2020",
            "status_type": "actual",
            "data": {
                "date": "2020-01-01T00:00:00.000Z",
                "internal_volume": 792709374
            }
        },
        {
            "month_year": "February 2020",
            "status_type": "actual",
            "data": {
                "date": "2020-02-01T00:00:00.000Z",
                "internal_volume": 744521973
            }
        },
        {
            "month_year": "March 2020",
            "status_type": "actual",
            "data": {
                "date": "2020-03-01T00:00:00.000Z",
                "internal_volume": 781842324
            }
        },
        {
            "month_year": "April 2020",
            "status_type": "actual",
            "data": {
                "date": "2020-04-01T00:00:00.000Z",
                "internal_volume": 891098024
            }
        },
        {
            "month_year": "May 2020",
            "status_type": "actual",
            "data": {
                "date": "2020-05-01T00:00:00.000Z",
                "internal_volume": 770965109
            }
        },
        {
            "month_year": "June 2020",
            "status_type": "actual",
            "data": {
                "date": "2020-06-01T00:00:00.000Z",
                "internal_volume": 892223774
            }
        },
        {
            "month_year": "July 2020",
            "status_type": "actual",
            "data": {
                "date": "2020-07-01T00:00:00.000Z",
                "internal_volume": 830597426
            }
        },
        {
            "month_year": "August 2020",
            "status_type": "actual",
            "data": {
                "date": "2020-08-01T00:00:00.000Z",
                "internal_volume": 774837879
            }
        },
        {
            "month_year": "September 2020",
            "status_type": "actual",
            "data": {
                "date": "2020-09-01T00:00:00.000Z",
                "internal_volume": 844639281
            }
        },
        {
            "month_year": "October 2020",
            "status_type": "actual",
            "data": {
                "date": "2020-10-01T00:00:00.000Z",
                "internal_volume": 830206995
            }
        },
        {
            "month_year": "November 2020",
            "status_type": "actual",
            "data": {
                "date": "2020-11-01T00:00:00.000Z",
                "internal_volume": 745851365
            }
        },
        {
            "month_year": "December 2020",
            "status_type": "actual",
            "data": {
                "date": "2020-12-01T00:00:00.000Z",
                "internal_volume": 751501813
            }
        },
        {
            "month_year": "January 2021",
            "status_type": "actual",
            "data": {
                "date": "2021-01-01T00:00:00.000Z",
                "internal_volume": 730768899
            }
        },
        {
            "month_year": "February 2021",
            "status_type": "actual",
            "data": {
                "date": "2021-02-01T00:00:00.000Z",
                "internal_volume": 644496302
            }
        },
        {
            "month_year": "March 2021",
            "status_type": "forecasted",
            "data": {
                "date": "2021-03-01T00:00:00.000Z",
                "internal_volume": 780552320
            }
        },
        {
            "month_year": "April 2021",
            "status_type": "forecasted",
            "data": {
                "date": "2021-04-01T00:00:00.000Z",
                "internal_volume": 773206784
            }
        },
        {
            "month_year": "May 2021",
            "status_type": "forecasted",
            "data": {
                "date": "2021-05-01T00:00:00.000Z",
                "internal_volume": 772444224
            }
        },
        {
            "month_year": "June 2021",
            "status_type": "forecasted",
            "data": {
                "date": "2021-06-01T00:00:00.000Z",
                "internal_volume": 773912512
            }
        },
        {
            "month_year": "July 2021",
            "status_type": "forecasted",
            "data": {
                "date": "2021-07-01T00:00:00.000Z",
                "internal_volume": 773795264
            }
        },
        {
            "month_year": "August 2021",
            "status_type": "forecasted",
            "data": {
                "date": "2021-08-01T00:00:00.000Z",
                "internal_volume": 772503680
            }
        },
        {
            "month_year": "September 2021",
            "status_type": "forecasted",
            "data": {
                "date": "2021-09-01T00:00:00.000Z",
                "internal_volume": 774733120
            }
        },
        {
            "month_year": "October 2021",
            "status_type": "forecasted",
            "data": {
                "date": "2021-10-01T00:00:00.000Z",
                "internal_volume": 785583168
            }
        },
        {
            "month_year": "November 2021",
            "status_type": "forecasted",
            "data": {
                "date": "2021-11-01T00:00:00.000Z",
                "internal_volume": 805461184
            }
        },
        {
            "month_year": "December 2021",
            "status_type": "forecasted",
            "data": {
                "date": "2021-12-01T00:00:00.000Z",
                "internal_volume": 827674304
            }
        },
        {
            "month_year": "January 2022",
            "status_type": "forecasted",
            "data": {
                "date": "2022-01-01T00:00:00.000Z",
                "internal_volume": 840981376
            }
        },
        {
            "month_year": "February 2022",
            "status_type": "forecasted",
            "data": {
                "date": "2022-02-01T00:00:00.000Z",
                "internal_volume": 835960768
            }
        },
        {
            "month_year": "March 2022",
            "status_type": "forecasted",
            "data": {
                "date": "2022-03-01T00:00:00.000Z",
                "internal_volume": 811219008
            }
        },
        {
            "month_year": "April 2022",
            "status_type": "forecasted",
            "data": {
                "date": "2022-04-01T00:00:00.000Z",
                "internal_volume": 775250752
            }
        },
        {
            "month_year": "May 2022",
            "status_type": "forecasted",
            "data": {
                "date": "2022-05-01T00:00:00.000Z",
                "internal_volume": 742426176
            }
        },
        {
            "month_year": "June 2022",
            "status_type": "forecasted",
            "data": {
                "date": "2022-06-01T00:00:00.000Z",
                "internal_volume": 725362752
            }
        },
        {
            "month_year": "July 2022",
            "status_type": "forecasted",
            "data": {
                "date": "2022-07-01T00:00:00.000Z",
                "internal_volume": 728298112
            }
        },
        {
            "month_year": "August 2022",
            "status_type": "forecasted",
            "data": {
                "date": "2022-08-01T00:00:00.000Z",
                "internal_volume": 745417024
            }
        },
        {
            "month_year": "September 2022",
            "status_type": "forecasted",
            "data": {
                "date": "2022-09-01T00:00:00.000Z",
                "internal_volume": 764890112
            }
        }
    ]

    return { data: data };
}());