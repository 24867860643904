import { Redirect, Route, Router, Switch } from "react-router-dom";
import { applyMiddleware, compose, createStore } from "redux";

import ChangePassword from "./pages/Menu/ChangePassword";
import Dashboard from "./pages/Dashboard";
import ForgotPassword from "./pages/Login/ForgotPassword";
import Login from "./pages/Login";
import { Provider } from "react-redux";
import React from "react";
import UpdatePassword from "./pages/Login/UpdatePassword";
import UserMenu from "./pages/Menu";
import createSagaMiddleware from "redux-saga";
import history from "./helpers/roleAccess/privateRoute";
import rootReducer from "./redux-saga/reducers";
import rootSaga from "./redux-saga/sagas";

// import {
//   Gallon,
//   Geo,
//   InternalSales,
//   Kantar,
//   Nielsen,
//   Sps,
// } from "./pages/Forecasting";


// import { MsalProvider } from "@azure/msal-react";








// import NotFound from "./pages/NotFound";
// import Error from "./pages/Error";
// import { Role, RoleMenu, User } from "./pages/Setting";\

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);
const Routes = () =>
  // { pca }
  {
    return (
      <Provider store={store}>
        <Router history={history}>
          {/* <MsalProvider instance={pca}> */}
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>

            <Route path="/login" component={Login} />
            <Route path="/resetpassword" component={UpdatePassword} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/user-menu" component={UserMenu} />
            <Route path="/sudestada-sales" component={Dashboard} />
            {/* <Route path="/update-password" component={UpdatePassword} /> */}
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/change-password" component={ChangePassword} />

            {/* <PrivateRoute exact path="/nielsensps" component={Nielsen} />
          <PrivateRoute exact path="/kantargallon" component={Kantar} />
          <PrivateRoute exact path="/simulatorsps" component={Sps} />
          <PrivateRoute exact path="/simulatorgallon" component={Gallon} />
          <PrivateRoute exact path="/geoexpansion" component={Geo} />

          <Route exact path="/setting/user" component={User} />
          <Route exact path="/setting/role" component={Role} />
          <Route exact path="/setting/rolemenu" component={RoleMenu} />
          <Route exact path="/error" component={Error} />

          <Route component={NotFound} />
          <Redirect to="/notfound" /> */}
          </Switch>
          {/* </MsalProvider> */}
        </Router>
      </Provider>
    );
  };

export default Routes;
