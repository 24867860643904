import React from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import "./layout.scss";

const FullLayout = (props) => {
    return (
        <div className="layout-wrapper">
            <div className="item1">
                <Header />
            </div>
            <div className="item2">
                <Sidebar />
            </div>
            <div className="item3">
                {props.children}
            </div>
        </div>
    )
}
export default FullLayout