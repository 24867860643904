import CloseMenu from "../../helpers/CloseMenu";
import React from "react";

const ExportMenu = ({
  showExport,
  handleShowExport,
  handleExportPPTX,
  handleExportExcel,
}) => {
  return (
    <div className="export-button">
      <i
        className="fa fa-download fa-lg"
        aria-hidden="true"
        onClick={handleShowExport}
      ></i>
      {showExport && (
        <CloseMenu handleShow={handleShowExport}>
          <div className="dropdown-content">
            <div className="button-list" onClick={() => handleExportPPTX()}>
              <i
                className="fa fa-file-powerpoint-o fa-lg"
                aria-hidden="true"
                style={{ color: "orange" }}
              />
              &nbsp;&nbsp;Power Point
            </div>
            <hr />
            <div className="button-list" onClick={handleExportExcel}>
              <i
                className="fa fa-file-excel-o fa-lg"
                aria-hidden="true"
                style={{ color: "green" }}
              />
              &nbsp;&nbsp;Excel
            </div>
          </div>
        </CloseMenu>
      )}
    </div>
  );
};

export default ExportMenu;
