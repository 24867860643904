import React, { Component } from "react";

export class InputTextBox extends Component {
  render() {
    const { height, width, color, label, type, onchange, value, placeholder } =
      this.props;
    return (
      <div style={{ marginTop: 10 }}>
        <label style={{ fontWeight: "600" }}>
          {label}
          <input
            placeholder={placeholder !== undefined ? placeholder : ""}
            type={type !== undefined ? type : "text"}
            value={value}
            style={{
              paddingLeft: 5,
              height: height,
              width: width,
              borderRadius: 5,
              marginTop: 7,
              border: "solid 1px" + color,
            }}
            onChange={onchange}
          />
        </label>
      </div>
    );
  }
}
export default InputTextBox;
