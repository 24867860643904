import * as fs from "file-saver";

import { Workbook } from "exceljs";

const excelTrails = (dataUser) => {
  let workbook = new Workbook();
  let trails = workbook.addWorksheet("Audit Trails");
  trails.addRow(["ID", "Access Time", "Menu", "Username"]);
  dataUser.forEach((val, idx) => {
    let tmp = [];
    tmp.push(val.id);
    tmp.push(val.created_at);
    tmp.push(val.menu);
    tmp.push(val.username);
    trails.addRow(tmp);
  });
  let fname = "Sudestada Forecasting - Export Audit Trails";
  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, fname + ".xlsx");
  });
};

export default excelTrails;
