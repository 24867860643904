import { call, put, takeEvery } from "redux-saga/effects";

import Swal from "sweetalert2";
import { addUser } from "../../actions/userList";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function addUserAPI(payload) {
  let url = apiUrl + "/api/v1/user/create";
  const data = {
    email: payload.email,
    username: payload.username,
    password: payload.password,
  };
  let token = localStorage.getItem("token");

  let config = { headers: { Authorization: `Bearer ${token}` } };

  return axios.post(url, data, config);
}

export function* addUserRequest(action) {
  try {
    const response = yield call(addUserAPI, action.payload);

    if (response) {
      yield put(
        addUser.success({
          data: response.data,
        })
      );
      Swal.fire({
        icon: "success",
        title: "Add User Success",
      });
    }
  } catch (error) {
    yield put(addUser.failure(error.message));
  }
}

export default function* addUserSaga() {
  yield takeEvery(addUser.REQUEST, addUserRequest);
}
