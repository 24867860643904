import "../login.scss";

import React, { useState, useEffect } from "react";

import history from "../../../helpers/roleAccess/privateRoute";

import { connect } from "react-redux";
import { func } from "prop-types";
import { updatePassword } from "../../../redux-saga/actions/userList";

const UpdatePasswordClass = (props) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [samePasswordCheck, setSamePasswordCheck] = useState(false);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const token = Object.fromEntries(urlSearchParams.entries());

  const handleInputChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleInputChangePasswordRepeat = (e) => {
    setRepeatPassword(e.target.value);
  };

  const handleUpdatePassword = async () => {
    let query = {
      token: token.token,
      password: password,
      repeat_password: repeatPassword
    };
    setLoading(true)
    await props.updatepassword(query);
  };
  useEffect(() => {
    const regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_=+]).{8,}$")
    if (password.length > 1) {
      if (!regex.test(password)) {
        console.log(regex.test(password));
        setPasswordValid(true)
      }
    }
    if (regex.test(password)) {
      setPasswordValid(false)
    }
    if (repeatPassword.length > 5) {
      if (repeatPassword !== password) {
        setSamePasswordCheck(true)
      }
    }
    if (repeatPassword === password) {
      setSamePasswordCheck(false)
    }
  }, [password, repeatPassword])

  useEffect(() => {
    if (props.pending === false) {
      setLoading(false)
    }
  }, [props.pending])
  return (
    <div className="login-wrapper">
      <div className="login-content-wrapper">
        <div className="brand-logo">
          <img className="img" src="./main-logo1.png" alt="" />
          <img className="img2" src="./main-logo2.png" alt="" />
          <text className="text-update-password">Update Password</text>
        </div>
        <hr />
        <div className="input-form">
          <input
            type="password"
            placeholder="New Password"
            value={password}
            name="newPassword"
            onChange={handleInputChangePassword}
          />
          {passwordValid === true ?
          <div>
            <p style={{ color: "red", fontSize: 14, margin: 3 }}>- Password must contain more than 8 character</p>
            <p style={{ color: "red", fontSize: 14, margin: 3 }}>- Password must contain at least 1 uppercase, 1 number, 1 special character</p>
            </div> :
            null
          }
          <input
            type="password"
            placeholder="Confirm New Password"
            value={repeatPassword}
            name="confirmNewPassword"
            onChange={handleInputChangePasswordRepeat}
          />
          {samePasswordCheck === true ?
            <p style={{ color: "red", fontSize: 14, margin: 3 }}>Please input the same password</p> :
            null
          }
          <button style={{ backgroundColor: samePasswordCheck ||passwordValid ? "#9b9b9b" : "#0077c5" }} onClick={handleUpdatePassword} disabled={samePasswordCheck||passwordValid} >
            {loading ? "Update..." : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
};
UpdatePasswordClass.propTypes = {
  updatepassword: func.isRequired,
};
const mapStateToProps = ({ userList: { response_update_password, pending, error } }) => ({
  response_update_password,
  pending,
  error,
});
const mapDispatchToProps = (dispatch) => ({
  updatepassword: (query) => dispatch(updatePassword.request(query)),
});
const UpdatePassword = connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordClass);

export default UpdatePassword;
