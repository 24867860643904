import "./styles.scss";

import React, { useEffect, useState } from "react";

import { InputTextBox } from "../../Form";
import Modal from "react-modal";

const UserTable = ({
  listUser,
  edit,
  handleDeleteUser,
  showModal,
  setShowModal,
}) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [menu, setMenu] = useState("");
  const [id, setId] = useState("");

  const customStyles = {
    content: {
      paddingTop: 0,
      width: 300,
      margin: "auto",
      height: menu === "edit" ? "45%" : "25%",
      zIndex: 1300,
    },
  };
  const editUser = (user, menu) => {
    setUsername(user.username);
    setEmail(user.email);
    setId(user.id);
    setMenu(menu);
    setShowModal(true);
  };

  const deleteUser = (user, menu) => {
    setUsername(user.username);
    setEmail(user.email);
    setId(user.id);
    setMenu(menu);
    setShowModal(true);
  };

  const handleOpenCloseModal = () => {
    setShowModal(false);
  };

  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div>
      <table>
        <thead className="thead">
          <tr>
            <td className="th" align="left">
              Name
            </td>

            <td className="th" align="left">
              Email
            </td>

            <td className="th" align="right">
              Action
            </td>
          </tr>
        </thead>

        <tbody className="tbody">
          {listUser &&
            listUser.map((item, i) => (
              <tr>
                <td align="left">{item.username}</td>
                <td align="left">{item.email}</td>

                <td align="right">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      className="button-table"
                      onClick={() => editUser(item, "edit")}
                    >
                      edit
                    </div>
                    <div
                      className="button-table"
                      onClick={() => deleteUser(item, "delete")}
                    >
                      delete
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <Modal
        ariaHideApp={false}
        isOpen={showModal}
        style={customStyles}
        contentLabel="Minimal Modal Example"
      >
        <div
          onClick={handleOpenCloseModal}
          style={{
            position: "absolute",
            top: 2,
            right: 15,
            color: "red",
            fontSize: 20,
            cursor: "pointer",
            zIndex: 1,
          }}
        >
          X
        </div>

        {menu === "edit" ? (
          <div>
            <div>
              <div className="title-modal">Edit Item</div>
              <div className="text-modal">
                Are you sure want to edit this item?
              </div>

              <div className="select-menu" style={{ marginTop: 15 }}>
                <InputTextBox
                  label={"Username"}
                  value={username}
                  height={30}
                  width="98%"
                  color={"#002370"}
                  placeholder={"Username"}
                  onchange={handleChangeUsername}
                />

                <InputTextBox
                  label={"email"}
                  value={email}
                  height={30}
                  width="98%"
                  color={"#002370"}
                  placeholder={"email"}
                  onchange={handleChangeEmail}
                />
              </div>
            </div>
            <div
              onClick={() => edit(username, email, id)}
              className="button-modal"
            >
              {" "}
              Edit
            </div>
          </div>
        ) : (
          <div>
            <div className="title-modal">Delete Item</div>
            <div className="text-modal">
              Are you sure want to delete {username}?
            </div>
            <div onClick={() => handleDeleteUser(id)} className="button-modal">
              Delete
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default UserTable;
