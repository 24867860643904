import { call, put, takeEvery } from "redux-saga/effects";

import axios from "axios";
import { getAuditTrails } from "../../actions/auditTrails";
const apiUrl = process.env.REACT_APP_API_URL;

export function auditTrailsAPI(payload) {
  let url =
    `${apiUrl}/api/v1/audit-trail/export?username=` +
    payload.username +
    `&start_date=` +
    payload.start_date +
    `&end_date=` +
    payload.end_date +
    ``;
  if (payload.username === undefined) {
    url =
      `${apiUrl}/api/v1/audit-trail/export?start_date=` +
      payload.start_date +
      `&end_date=` +
      payload.end_date +
      ``;
  }
  let token = localStorage.getItem("token");
  let config = { headers: { Authorization: `Bearer ${token}` } };
  return axios.get(url, config);
}

export function* auditTrailsRequest(action) {
  try {
    const response = yield call(auditTrailsAPI, action.payload);
    if (response) {
      yield put(
        getAuditTrails.success({
          data: response.data.data,
        })
      );
    }
  } catch (error) {
    yield put(getAuditTrails.failure(error.message));
  }
}

export default function* getAuditSaga() {
  yield takeEvery(getAuditTrails.REQUEST, auditTrailsRequest);
}
