import { call, put, takeEvery } from "redux-saga/effects";

import Swal from "sweetalert2";
import axios from "axios";
import { changePassword } from "../../actions/userList";

const apiUrl = process.env.REACT_APP_API_URL;

export function ChangePasswordAPI(payload) {
  const url = apiUrl + "/api/v1/user/change/password";
  const data = {
    old_password: payload.old_password,
    password: payload.password,
    repeat_password: payload.repeat_password,
  };
  let userToken = localStorage.getItem("token");
  let config = { headers: { Authorization: `Bearer ${userToken}` } };

  return axios.post(url, data, config);
}

export function* changePasswordRequest(action) {
  try {
    const response = yield call(ChangePasswordAPI, action.payload);

    if (response) {
      yield put(
        changePassword.success({
          data: response.data,
        })
      );

      Swal.fire({
        icon: "success",
        title: "Update Password",
        text: " Succes to update password",
        showConfirmButton: true,
      });
    }
  } catch (error) {
    yield put(changePassword.failure(error.message));
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.response.data.message,
    });
  }
}

export default function* changePasswordSaga() {
  yield takeEvery(changePassword.REQUEST, changePasswordRequest);
}
