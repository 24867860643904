import "./login.scss";

import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import Swal from "sweetalert2";
import { func } from "prop-types";
import history from "../../helpers/roleAccess/privateRoute";
import { loginAPI } from "../../redux-saga/sagas/user/login";
import { postAuditTrails } from "../../redux-saga/actions/auditTrails";
import { postLogin } from "../../redux-saga/actions/login";
import { withRouter } from "react-router-dom";

const LoginClass = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    //function here
    setData({
      ...data,
      email: e.target.value,
    });
  };

  const handleInputChangePassword = (e) => {
    //function here
    setData({
      ...data,
      password: e.target.value,
    });
  };

  const handleLogin = async () => {
    await props.login(data);
  };

  const handleForgotPassword = () => {
    history.replace("forgot-password");
  };
  useEffect(() => {
    const data = {
      username: localStorage.getItem("username"),
      menu: "Login",
    };
    props.postAudit(data);
  }, []);

  return (
    <div className="login-wrapper">
      <div className="login-content-wrapper">
        <div className="brand-logo">
          <img className="img" src="./main-logo1.png" alt="" />
          <img className="img2" src="./main-logo2.png" alt="" />
          <text className="text-login">LOGIN</text>
        </div>
        <hr />
        <div className="input-form">
          <input
            type="text"
            placeholder="Email"
            value={data.email}
            name="email"
            onChange={handleInputChange}
          />
          <input
            type="password"
            placeholder="Password"
            value={data.password}
            name="password"
            onChange={handleInputChangePassword}
          />
          <div className="text-forgot-password" onClick={handleForgotPassword}>
            forgot password
          </div>
          <button onClick={handleLogin} disabled={loading}>
            {loading ? "Loading..." : "Login"}
          </button>
        </div>
      </div>
    </div>
  );
};

LoginClass.propTypes = {
  login: func.isRequired,
  postAudit: func.isRequired,
};

const mapStateToProps = ({ dologin: { response_login, pending, error } }) => ({
  response_login,
  pending,
  error,
});

const mapDispatchToProps = (dispatch) => ({
  login: (user_login) => dispatch(postLogin.request(user_login)),
  postAudit: (data) => dispatch(postAuditTrails.request(data)),
});

const Login = connect(mapStateToProps, mapDispatchToProps)(LoginClass);

export default withRouter(Login);
