import "./index.scss";

import FullLayout from "../FullLayout";
import React from "react";
import SideMenu from "../SideMenu";

const LayoutWithMenu = (props) => {
  const listMenu = [
    {
      name: "Sudestada Sales",
      url: "/sudestada-sales",
    },
    // {
    //   name: "Water Penetration (Kantar)",
    //   url: "/kantar/waterpenetration",
    // },
    // {
    //   name: "Water AWOP (Kantar)",
    //   url: "/kantar/waterawop",
    // },
    // {
    //   name: "Isotonic Penetration (Kantar)",
    //   url: "/kantar/isotonicpenetration",
    // },
  ];
  return (
    <FullLayout>
      <div className="layout-with-menu">
        <SideMenu listMenu={listMenu} title="Menu" />
        {props.children}
      </div>
    </FullLayout>
  );
};
export default LayoutWithMenu;
