import React, { useRef, useEffect } from "react";

const CloseMenu = (props) => {
  const wrapperRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        props.handleShow();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  return <div ref={wrapperRef}>{props.children}</div>;
};

export default CloseMenu;
