import {
  changePassword,
  forgotPassword,
  getListUser,
  updatePassword,
} from "../actions/userList";

import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  response_list_user: [],
  response_change_password: [],
  response_forgot_password: [],
  response_update_password: [],
  pending: true,
  error: false,
};

const userList = handleActions(
  {
    [getListUser.REQUEST]: (state, { payload }) => ({
      ...state,
    }),
    [getListUser.SUCCESS]: (state, { payload }) => ({
      ...state,
      response_list_user: payload.data,
      pending: false,
      error: false,
    }),
    [getListUser.FAILURE]: (state) => ({
      ...state,
      error: true,
      pending: false,
    }),
    [changePassword.REQUEST]: (state, { payload }) => ({
      ...state,
      old_password: payload.old_password,
      password: payload.password,
      repeat_password: payload.repeat_password,
    }),
    [changePassword.SUCCESS]: (state, { payload }) => ({
      ...state,
      response_change_password: payload.data,
      pending: false,
      error: false,
    }),
    [changePassword.FAILURE]: (state) => ({
      ...state,
      error: true,
      pending: false,
    }),
    [forgotPassword.REQUEST]: (state, { payload }) => ({
      ...state,
      password: payload.password,
    }),
    [forgotPassword.SUCCESS]: (state, { payload }) => ({
      ...state,
      response_forgot_password: payload.data,
      pending: false,
      error: false,
    }),
    [forgotPassword.FAILURE]: (state) => ({
      ...state,
      error: true,
      pending: false,
    }),
    [updatePassword.REQUEST]: (state, { payload }) => ({
      ...state,
      token: payload.token,
      password: payload.password,
      repeat_password: payload.repeat_password,
      pending: true,
    }),
    [updatePassword.SUCCESS]: (state, { payload }) => ({
      ...state,
      response_update_password: payload.data,
      pending: false,
      error: false,
    }),
    [updatePassword.FAILURE]: (state) => ({
      ...state,
      error: true,
      pending: false,
    }),
  },
  INITIAL_STATE
);

export default userList;
