import "./InternalSales.scss";
import "./data";

import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getFilterCategory,
  getFilterDepartment,
  getFilterItem,
} from "../../redux-saga/actions/filterMenu";

import ExportExcelSales from "../../helpers/ExportExcelSales";
import ExportMenu from "../../components/ExportMenu";
import FilterCardInternalSales from "../../components/FilterCardInternalSales";
import LayoutWithMenu from "../../components/Container/Layout/layoutWithMenu";
import LineChart from "./LineChart";
import { SalesForecastPPT } from "../../helpers/ExportPpt/Sales/SalesForecast";
import { func } from "prop-types";
import { getForecastData } from "../../redux-saga/actions/forecastData";
import moment from "moment";
import { withRouter } from "react-router-dom";

const InternalSalesClass = (props) => {
  const [loadingFilter, setLoadingFilter] = useState(true);
  const [occupancy, setOccupancy] = useState(100);
  const [dataInternal, setDataInternal] = useState([]);
  const [dataSales, setDataSales] = useState([]);
  const [selectedDepartement, setSelectedDepartement] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);

  const [itemInput, setItemInput] = useState("");
  const [filteredItem, setFilteredItem] = useState([]);

  const handleItemInput = (e) => {
    setItemInput(e.target.value);

    const value = e.target.value;

    if (value.length !== 0) {
      const filtered = props.response_filter_item.filter((item) => {
        return item.item_name.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredItem(filtered);
    } else {
      setFilteredItem(props.response_filter_item);
    }
  };

  const [dataExcell, setDataExcell] = useState([]);

  const handleSelectDepartement = (e) => {
    setSelectedDepartement(e.value);
    setSelectedCategory([]);
    setSelectedItem([]);
    setDataSales([]);
  };

  const handleSelectCategory = async (e) => {
    setSelectedCategory(e);
    setSelectedItem([]);
  };

  const handleSelectItem = async (e) => {
    setSelectedItem(e);
  };

  const handleOccupancy = async (e) => {
    setOccupancy(e);
  };

  const fetchData = async () => {
    const queryParam = [
      selectedDepartement,
      selectedCategory.value,
      selectedItem.value]
    await props.getdata(queryParam);
  };
  useEffect(() => {
    if (props.response_forecast_data.length !== 0) {
      setDataSales(props.response_forecast_data);
    }
  }, [props.response_forecast_data]);

  useEffect(() => {
    if (props.response_filter_item !== 0) {
      setFilteredItem(props.response_filter_item);
    }
  }, [props.response_filter_item]);

  useEffect(() => {
    if (selectedItem.length !== 0) {
      fetchData();
    }
  }, [selectedItem]);

  useEffect(() => {
    fetchFilter();
  }, []);

  useEffect(() => {
    fetchFilterCategory();
  }, [selectedDepartement]);

  useEffect(() => {
    fetchFilterItem();
  }, [selectedCategory]);

  const fetchFilter = async () => {
    try {
      await props.getFilterDepartment();
    } catch (err) {
      console.error(err, "<<<<<<< Get Filter Departemen");
    }
  };
  const fetchFilterCategory = async () => {
    try {
      await props.getFilterCategory(selectedDepartement);
    } catch (error) {
      console.log(error, "<<<<<<<<< Get Filter Category");
    }
  };
  const fetchFilterItem = async () => {
    try {
      const data_query = {
        departement: selectedDepartement,
        category: selectedCategory.value,
      };
      await props.getFilterItem(data_query);
    } catch (error) {
      console.log(error, "<<<<<<<< Get Filter Item");
    }
  };

  const [showExport, setShowExport] = useState(false);
  const handleShowExport = () => {
    setShowExport(!showExport);
  };

  const [dataInternalExport, setDataInternalExport] = useState([]);
  const handleExportPPTX = () => {
    SalesForecastPPT(dataInternalExport);
  };

  const handleExportExcel = () => {
    if (dataSales.length !== 0) {
      ExportExcelSales(dataSales, occupancy);
    }
  };

  return (
    <LayoutWithMenu>
      <div
        style={{
          position: "absolute",
          backgroundColor: "#04509c",
          right: 0,
          padding: "10px 5px 10px 20px",
          borderRadius: "0 0 0 10px",
        }}
      >
        <ExportMenu
          showExport={showExport}
          handleShowExport={handleShowExport}
          handleExportPPTX={handleExportPPTX}
          handleExportExcel={handleExportExcel}
        />
      </div>
      <div className="internalsales-wrapper" id="internalsales-wrapper">
        <div className="internalsales-details-card1 content">
          {/* 
          {loadingFilter ? (
            <div>Loading...</div>
          ) : (*/}
          <FilterCardInternalSales
            selectedDepartement={selectedDepartement}
            selectedCategory={selectedCategory}
            handleSelectCategory={handleSelectCategory}
            dataDepartment={props.response_filter_department}
            dataCategory={props.response_filter_category}
            occupancy={occupancy}
            handleOccupancy={handleOccupancy}
            // dataItem={props.response_filter_item}
            handleSelectDepartement={handleSelectDepartement}
            filteredItem={filteredItem}
            itemInput={itemInput}
            handleItemInput={handleItemInput}
            selectedItem={selectedItem}
            occupancy={occupancy}
            setOccupancy={setOccupancy}
            handleSelectItem={handleSelectItem}
          />
          {/*  )} */}
        </div>
        <div className="internalsales-details-card2 content">
          {/* {loading ? (
            <div>Loading...</div>
          ) : ( */}
          <LineChart
            data={dataInternal}
            datas={dataSales}
            occupancy={occupancy}
            exportData={setDataInternalExport}
          />
          {/* )} */}
        </div>
      </div>
    </LayoutWithMenu>
  );
};

InternalSalesClass.propTypes = {
  getFilterDepartment: func.isRequired,
  getFilterCategory: func.isRequired,
  getFilterItem: func.isRequired,
  getdata: func.isRequired,
};
const mapStateToProps = ({
  filterMenu: {
    response_filter_department,
    response_filter_category,
    response_filter_item,
    pending,
    error,
  },
  forecast: { response_forecast_data },
}) => ({
  response_forecast_data,
  response_filter_department,
  response_filter_category,
  response_filter_item,
  pending,
  error,
});

const mapDispatchToProps = (dispatch) => ({
  getFilterDepartment: () => dispatch(getFilterDepartment.request()),
  getFilterCategory: (departement) =>
    dispatch(getFilterCategory.request(departement)),
  getFilterItem: (data_query) => dispatch(getFilterItem.request(data_query)),
  getdata: (data_query) => dispatch(getForecastData.request(data_query)),
});

const InternalSales = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalSalesClass);

export default withRouter(InternalSales);
