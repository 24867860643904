import React from "react";
import history from "../../../../helpers/roleAccess/privateRoute";
import "./sidebar.scss";

const Sidebar = () => {
  return (
    <div className="sidebar-wrapper">
      <i
        className="fa fa-home fa-lg"
        aria-hidden="true"
        onClick={() => history.push("/dashboard")}
      />
    </div>
  );
};

export default Sidebar;
