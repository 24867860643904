import audit from "./auditTrails";
import { combineReducers } from "redux";
import dologin from "./login";
import filterMenu from "./filterMenu";
import userList from "./userList";
import forecast from "./forecastData";

export default combineReducers({
  dologin,
  userList,
  audit,
  filterMenu,
  forecast
});
