import { call, put, takeEvery } from "redux-saga/effects";

import axios from "axios";
import { getFilterItem } from "../../actions/filterMenu";

const apiUrl = process.env.REACT_APP_API_URL;

export function getItemListAPI(payload) {
  const departement = encodeURIComponent(payload.departement)
  const category = encodeURIComponent(payload.category)
  const url =
    apiUrl +
    `/api/v1/forecast/filter/item?departement=${departement}&category=${category}`;
  let userToken = localStorage.getItem("token");
  let config = { headers: { Authorization: `Bearer ${userToken}` } };
  return axios.get(url, config);
}

export function* getItemListRequest(action) {
  try {
    const response = yield call(getItemListAPI, action.payload);
    if (response) {
      yield put(
        getFilterItem.success({
          data: response.data.data,
        })
      );
    }
  } catch (error) {
    yield put(getFilterItem.failure(error.message));
  }
}

export default function* ItemListFilterSaga() {
  yield takeEvery(getFilterItem.REQUEST, getItemListRequest);
}
