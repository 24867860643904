import "../menu.scss";

import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";

import FullLayout from "../../../components/Container/Layout/FullLayout";
import { InputTextBox } from "../../../components/Form";
import { changePassword } from "../../../redux-saga/actions/userList";
import { func } from "prop-types";

const ChangePasswordClass = (props) => {
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const [oldPassword, setOldPassword] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState(null);
  const [newPasswordValidation, setNewPasswordValidation] = useState(false);
  const [confirmNewPasswordValidation, setConfirmNewPasswordValidation] =
    useState(false);
  const [statusOldPassword, setStatusOldPassword] = useState(false);

  const handleChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
    const regPassword =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).(?=.{14})/;
    if (regPassword.test(newPassword)) setNewPasswordValidation(false);
  };
  const handleChangeConfirmNewPassword = (e) => {
    setConfirmNewPassword(e.target.value);
    if (newPassword === confirmNewPassword)
      setConfirmNewPasswordValidation(false);
  };
  const handleChangeOldPassword = (e) => {
    setOldPassword(e.target.value);
  };
  const handleChangePassword = async () => {
    const data = {
      old_password: oldPassword,
      password: newPassword,
      repeat_password: confirmNewPassword,
    };
    const regPassword =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).(?=.{14})/;

    !regPassword.test(newPassword)
      ? setNewPasswordValidation(true)
      : setNewPasswordValidation(false);
    newPassword !== confirmNewPassword
      ? setConfirmNewPasswordValidation(true)
      : setConfirmNewPasswordValidation(false);
    if (newPassword === confirmNewPassword && regPassword.test(newPassword)) {
      await props.changePassword(data);
      setNewPassword("");
      setConfirmNewPassword("");
      setOldPassword("");
    }
  };
  return (
    <FullLayout>
      <div className="role-setting-wrapper">
        <div className="card-title">Change Password</div>
        <div className="form-container menu">
          <div>
            <InputTextBox
              label={"Old Password"}
              value={oldPassword}
              height={30}
              type="password"
              width="100%"
              color={"#002370"}
              placeholder={"Old Password"}
              onchange={handleChangeOldPassword}
            />
            <InputTextBox
              label={"New Password"}
              value={newPassword}
              height={30}
              type="password"
              width="100%"
              color={"#002370"}
              placeholder={"New Password"}
              onchange={handleChangeNewPassword}
            />
            {newPasswordValidation && (
              <p className="validation">
                Password must contain at least 15 characters, 1 uppercase, 1
                numeric, and 1 special character
              </p>
            )}

            <InputTextBox
              label={"Confirm New Password"}
              value={confirmNewPassword}
              height={30}
              width="100%"
              type="password"
              color={"#002370"}
              placeholder={"Confirm New Password"}
              onchange={handleChangeConfirmNewPassword}
            />

            {confirmNewPasswordValidation && (
              <p className="validation">
                The confirmation password does not match
              </p>
            )}

            <div
              style={{ width: "103%" }}
              className="buton-show big"
              onClick={handleChangePassword}
            >
              Change Password
            </div>
          </div>
        </div>
      </div>
    </FullLayout>
  );
};

ChangePasswordClass.propTypes = {
  changePassword: func.isRequired,
};

const mapStateToProps = ({
  userList: { response_change_password, pending, error },
}) => ({
  response_change_password,
  pending,
  error,
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: (data) => dispatch(changePassword.request(data)),
});

const ChangePassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordClass);
export default ChangePassword;
