import { call, put, takeEvery } from "redux-saga/effects";

import axios from "axios";
import { getFilterCategory } from "../../actions/filterMenu";

const apiUrl = process.env.REACT_APP_API_URL;

export function getCategoryAPI(payload) {
  const payloads = encodeURIComponent(payload)
  const url =
    apiUrl + `/api/v1/forecast/filter/category?departement=${payloads}`;
  let userToken = localStorage.getItem("token");
  let config = { headers: { Authorization: `Bearer ${userToken}` } };
  return axios.get(url, config);
}

export function* getCategoryListRequest(action) {
  try {
    const response = yield call(getCategoryAPI, action.payload);
    if (response) {
      yield put(
        getFilterCategory.success({
          data: response.data.data,
        })
      );
    }
  } catch (error) {
    yield put(getFilterCategory.failure(error.message));
  }
}

export default function* CategoryListFilterSaga() {
  yield takeEvery(getFilterCategory.REQUEST, getCategoryListRequest);
}
