import { call, put, takeEvery } from "redux-saga/effects";

import Swal from "sweetalert2";
import axios from "axios";
import history from "../../../helpers/roleAccess/privateRoute";
import { postLogin } from "../../actions/login";
const apiUrl = process.env.REACT_APP_API_URL;

export function loginAPI(payload) {
  const url = apiUrl + "/api/v1/user/login";
  const data = {
    email: payload.email,
    password: payload.password,
  };

  return axios.post(url, data);
}

export function* loginRequest(action) {
  try {
    const response = yield call(loginAPI, action.payload);

    if (response) {
      yield put(
        postLogin.success({
          data: response.data,
        })
      );
      console.log(response.data);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("username", response.data.username);
      Swal.fire({
        icon: "success",
        title: "Login Success",
        text: " Welcome to Sudestada Forecast!",
        showConfirmButton: false,
      });
      history.replace("/dashboard");
    }
  } catch (error) {
    yield put(postLogin.failure(error.message));
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "email or password invalid",
    });
  }
}

export default function* loginSaga() {
  yield takeEvery(postLogin.REQUEST, loginRequest);
}
